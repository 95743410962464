export interface BffUser {
    session_id: string;
    kundeId: number;
    kundeNavn: string;
    kontoId: number;
    kontoNavn: string;
    epost: string;
    navn: string;
    rolleId: UserRole;
    rolle: string;
    rettighet: number;
    brukerKey: string;
    spraakId: number;
    kundegruppe: string;
    produktpakke: string;
    logonDatoTidspunkt: string;
    aktiv: boolean;
    opprettet: string;
    lisensTil: string | null;
    demokonto: boolean;
    applicationId: number;
    krevSSO: boolean;
    epostSuffiks: string;
    initials: string;
    tilganger: {
        id: number;
        navn: string;
        produkter: {id: number; navn: string}[];
    }[];
}

export enum UserRole {
    Kunde = 1,
    KundePriviligert = 2,
    Fagarbeider = 3,
    Administrator = 4,
    Superbruker = 5,
    Kundeadmin = 6,
    Selger = 7,
}

export const HasNoAdminPrivileges = [UserRole.Kunde, UserRole.Selger];

export const HasAdminPrivileges = [
    UserRole.KundePriviligert,
    UserRole.Fagarbeider,
    UserRole.Administrator,
    UserRole.Superbruker,
    UserRole.Kundeadmin,
];
export interface Message {
    id?: string;
    role: "system" | "user" | "assistant";
    content: string;
}

export interface RagProcess {
    language: string;
    message: string;
    service: string;
    tag: string;
}

export interface Pipeline {
    description: string;
    model: string;
    name: string;
    promptid: string;
    tag: string;
    outputformat: string;
}

export interface RagSource {
    title: string;
    topictype: string;
    topicid: string;
    resourceid: string;
    segmentid?: string;
    bookmark?: string;
    foundin?: string;
    url?: string;
}

export type System = {
    name: string;
    queryAnalysis?: {
        pipeline: Pipeline[];
    };
    retrieval?: {
        pipeline: Pipeline[];
        searchindex: string;
    };
    completion?: {
        pipeline: Pipeline[];
    };
};

export interface Thread {
    id: string;
    name: string;
    system: System;
    datestamp: Date;
    messages: Message[];
}

export const isRagProcess = (obj: any): obj is RagProcess => {
    const requiredProps = ["language", "message", "service", "tag"];
    return requiredProps.every((prop) => prop in obj);
};

export const isRagSource = (obj: any): obj is RagSource => {
    const requiredProps = ["title", "topictype", "topicid", "resourceid"];
    return requiredProps.every((prop) => prop in obj);
};
