import {configureStore, ThunkAction, Action} from "@reduxjs/toolkit";
import chatSlice from "../features/chat/chatSlice";
import systemPipelineSlice from "../features/prompt-pipelines/systemPipelineSlice";
import {chatApi} from "../features/chat/chatApi";
import {bffApi} from "../features/chat/bffApi";
import {feedbackApi} from "../features/feedback/feedbackApi";
import {evaluationApi} from "../features/promptfoo/evaluationApi";
import {promptApi} from "../features/prompt-builder/promptApi";
import {responseStateMiddleware} from "./responseStateMiddleware";
import {testApi} from "../features/promptfoo/testApi";
import {productPipelineApi} from "../features/prompt-pipelines/productPipelineApi";

const isDevelopment = process.env.NODE_ENV !== "production";

export const store = configureStore({
    reducer: {
        chat: chatSlice,
        systemPipeline: systemPipelineSlice,
        [chatApi.reducerPath]: chatApi.reducer,
        [bffApi.reducerPath]: bffApi.reducer,
        [evaluationApi.reducerPath]: evaluationApi.reducer,
        [testApi.reducerPath]: testApi.reducer,
        [promptApi.reducerPath]: promptApi.reducer,
        [productPipelineApi.reducerPath]: productPipelineApi.reducer,
        [feedbackApi.reducerPath]: feedbackApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(chatApi.middleware)
            .concat(bffApi.middleware)
            .concat(evaluationApi.middleware)
            .concat(testApi.middleware)
            .concat(promptApi.middleware)
            .concat(productPipelineApi.middleware)
            .concat(feedbackApi.middleware)
            .concat(responseStateMiddleware),

    devTools: isDevelopment
        ? {
              trace: true,
              traceLimit: 25,
          }
        : false,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
