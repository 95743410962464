import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
    finishAssistantMessage,
    addPartialMessage,
    addRagProcess,
    addRagSource,
    updateThread,
} from "./chatSlice";
import {
    isRagProcess,
    isRagSource,
    RagProcess,
    RagSource,
    System,
    Thread,
} from "./types";

const openaiKey =
    "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";

interface SendMessageRequest {
    system: System;
    threadId?: string | null;
    message: string;
    messageId?: string;
    onThreadIdReceived?: (threadId: string) => void;
}

interface MessageStreamResponse {
    message: string;
    processes: RagProcess[];
    sources: RagSource[];
    done: boolean;
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const chatApi = createApi({
    reducerPath: "chatApi",
    baseQuery: fetchBaseQuery({baseUrl: "/api"}),
    tagTypes: ["MessageStream", "Threads", "Thread"],
    endpoints: (builder) => ({
        sendMessage: builder.mutation<
            MessageStreamResponse,
            SendMessageRequest
        >({
            queryFn: () => ({
                data: {
                    message: "",
                    processes: [],
                    sources: [],
                    done: false,
                },
            }),
            async onQueryStarted(
                {system, threadId, message, messageId, onThreadIdReceived},
                {dispatch, queryFulfilled}
            ) {
                if (!system) {
                    throw new Error("System configuration is not set.");
                }

                try {
                    const controller = new AbortController();

                    const response = await fetch("/api/Chat/chatCompletionV2", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${openaiKey}`,
                        },
                        body: JSON.stringify({
                            system,
                            threadId,
                            sliceMessageId: messageId,
                            message: {
                                role: "user",
                                content: message,
                            },
                        }),
                        signal: controller.signal,
                    });

                    if (!response.ok) {
                        throw new Error(
                            `Error ${response.status}: ${response.statusText}`
                        );
                    }

                    if (!response.body) {
                        throw new Error(
                            "ReadableStream not yet supported in this browser."
                        );
                    }

                    const reader = response.body.getReader();
                    const decoder = new TextDecoder("utf-8");
                    let done = false;
                    let accumulatedText = "";

                    while (!done) {
                        const {value, done: doneReading} = await reader.read();
                        done = doneReading;
                        const chunkValue = decoder.decode(value);

                        // Parse the SSE stream data
                        const lines = chunkValue
                            .split("\n")
                            .filter((line) => line.trim() !== "");

                        // extract the first line with threadId
                        /* const threadId = lines[0].includes("threadId:")
                            ? lines[0].replace(/^threadId:/, "")
                            : undefined;

                        if (threadId) {
                            onThreadIdReceived?.(threadId);
                        } */

                        for (const line of lines) {
                            const message = line.replace(/^data: /, "");

                            if (message === "[DONE]") {
                                done = true;
                                break;
                            } else {
                                try {
                                    const parsed = JSON.parse(message);

                                    if ("threadid" in parsed) {
                                        console.log(parsed);

                                        dispatch(
                                            updateThread({
                                                id: parsed.threadid,
                                                name: parsed.name,
                                                messages: parsed.messages,
                                            })
                                        );

                                        onThreadIdReceived?.(parsed.threadid);
                                    } else if ("choices" in parsed) {
                                        const content =
                                            parsed.choices[0]?.delta?.content;
                                        if (content) {
                                            accumulatedText += content;

                                            /* updateCachedData((draft) => {
                                                draft.message = `${draft.message}${content}`;
                                            }); */

                                            dispatch(
                                                addPartialMessage(content)
                                            );

                                            await sleep(10);
                                        }
                                    } else if (isRagProcess(parsed)) {
                                        dispatch(addRagProcess(parsed));
                                        /* updateCachedData((draft) => {
                                            draft.processes = draft.processes
                                                ? [...draft.processes, parsed]
                                                : [parsed];
                                        }); */
                                    } else if (isRagSource(parsed)) {
                                        dispatch(addRagSource(parsed));
                                        /* updateCachedData((draft) => {
                                            draft.sources = draft.sources
                                                ? [...draft.sources, parsed]
                                                : [parsed];
                                        }); */
                                    }
                                } catch (error) {
                                    console.error(
                                        "Could not JSON parse stream message",
                                        message,
                                        error
                                    );
                                }
                            }
                        }
                    }

                    dispatch(finishAssistantMessage(accumulatedText));

                    /* updateCachedData((draft) => {
                        draft.done = true;
                    }); */
                } catch (error) {
                    console.error("Error in streaming:", error);
                }

                await queryFulfilled;
            },
            invalidatesTags: ["Thread", "Threads"],
        }),
        likeMessage: builder.mutation<void, {messageId: string}>({
            query: ({messageId}) => ({
                url: `like`, // TODO - Update when endpoint has been implemented
                method: "PATCH",
                body: messageId,
            }),
        }),
        dislikeMessage: builder.mutation<void, {messageId: string}>({
            query: ({messageId}) => ({
                url: `dislike`, // TODO - Update when endpoint has been implemented
                method: "PATCH",
                body: messageId,
            }),
        }),
        getThreads: builder.query<Thread[], void>({
            query: () => ({
                url: `/Thread/GetThreads`,
                method: "GET",
            }),
            providesTags: ["Threads"],
        }),
        getThread: builder.query<Thread | undefined, string>({
            query: (threadId) => ({
                url: `/Thread/GetCompleteThread?id=${threadId}`,
                method: "GET",
            }),
            providesTags: ["Thread"],
        }),
        deleteThread: builder.mutation<void, string>({
            query: (id) => ({
                url: `/Thread/DeleteThread?id=${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Threads", "Thread"],
        }),
        rateMessage: builder.mutation<
            void,
            {messageId: string; score: number; comment: string}
        >({
            query: ({messageId, score, comment}) => ({
                url: `/Thread/rateMessage`,
                method: "PATCH",
                body: {messageId, score, comment},
            }),
        }),
        /* customChatCompletion: builder.mutation<
        customChatCompletion: builder.mutation<
            any,
            {model: string; systemPrompt: string; query: string}
        >({
            query: ({model, systemPrompt, query}) => ({
                url: `CustomChatCompletion`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: {
                    model,
                    systemPrompt,
                    query,
                },
            }),
        }), */
    }),
});

export const {
    useSendMessageMutation,
    useGetThreadsQuery,
    useGetThreadQuery,
    useDeleteThreadMutation,
    /* useCustomChatCompletionMutation, */
} = chatApi;
